
































































import ProductDialog from "@/components/dialogs/ProductDialog.vue"
import ProductComponent from "@/components/ProductComponent.vue"
import Product from "@/models/Product"
import ProductService from "@/services/ProductService"
import {Component, Vue, Watch} from "vue-property-decorator"
import ProductCategoryService from "@/services/ProductCategoryService";
import ProductCategory from "@/models/ProductCategory";
import {getModule} from "vuex-module-decorators";
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import CarouselComponent from "@/components/CarouselComponent.vue";
import {BannerReference} from "@/models/Banner";

@Component({components: { ProductComponent, ProductDialog, CarouselComponent }})
export default class VideoView extends Vue {

	categoryTreeviewModule = getModule(CategoryTreeviewModule)
	featuredProducts: Product[] = []
	featuredCategories: ProductCategory[] = []
	itemsPerPageArray = [4, 8, 12]
	product: Product = new Product()
	products: Product[] = []
	productDialog: boolean = false
	filter = {}
	sortDesc = false
	page = 1
	pageCount = 0
	totalItems = null
	itemsPerPage = 20
	sortBy = 'name'
	featuredCategory: ProductCategory | null = null

	get productCategories() { return this.categoryTreeviewModule.productCategories }
	get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
	set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }
	get bannerReference() { return BannerReference }

	created() {
		this.selectedCategories.splice(0, this.selectedCategories.length)
		this.refresh()
	}

	refresh() {
		ProductService.getFeaturedProducts(this, this.featuredProducts, 0, this.itemsPerPage, this.selectedCategories, null, true)
	}

	openProductDialog(product: Product) {
		this.productDialog = true
		this.product = product
		this.product.quantity = 1
	}

	treeToList(tree: ProductCategory) {
		const result = [];

		result.push(tree);
		const children = tree.children || [];
		children.forEach(child => {
			const childResult = this.treeToList(child);
			result.push(...childResult)
		})

		return result;
	}

	@Watch("featuredCategory")
	onFeaturedCategoryChanged() {
		if (this.featuredCategory != null) {
			let ids: number[] = []
			this.treeToList(this.featuredCategory).forEach(cat => ids.push(cat.id!))
			this.selectedCategories = ids
		} else {
			this.selectedCategories.splice(0, this.selectedCategories.length)
		}

		this.refresh()
	}

}
